import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { GridLargeWrapper, GridSmallWrapper } from '@/global/style/extends/Grid';
import { Color, spacing } from '@/global/style/variables';

import { StyledCheckmarkList as OfferFilterStyledCheckmarkList } from '@/components/Offers/OfferFilter.styled';

export const OfferCardsBlockSection = styled.section`
  background-color: ${Color.Hallon6};
`;

export const OfferCardsBlockContainer = styled.div`
  display: grid;
  ${GridLargeWrapper};
  padding-bottom: ${spacing.x7};
`;

export const OfferCardsBlockHeaderContainer = styled.div`
  position: relative;
  display: grid;
  gap: ${spacing.x2};
  padding-bottom: ${spacing.x2};

  &:not(:empty) {
    margin-top: ${spacing.x15};
    margin-bottom: ${spacing.x1};
  }

  ${above.sm`
    &:not(:empty) {
      margin-top: ${spacing.x3};
      margin-bottom: ${spacing.x4};
    }
  `}

  // display vertical in mobile
  ul {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const FinePrintContainer = styled.div`
  ${GridSmallWrapper}
`;

export const StyledCheckmarkList = styled(OfferFilterStyledCheckmarkList)`
  && {
    margin-top: 0;
  }
`;

export const OfferCardsWithFilterGrid = styled.div`
  display: grid;
  gap: ${spacing.x2};
  margin: 0 auto;
  width: 100%;

  ${above.sm`
    grid-template-columns: auto;
  `}

  .offer-filter {
    height: fit-content;

    ${above.lg`
      max-width: 235px;
    `}
  }
`;

export const OfferCardsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-column-gap: ${spacing.x2};
  grid-row-gap: ${spacing.x2};
  align-items: flex-start;
  overflow: hidden;

  ${above.sm`
    overflow: visible;
    background-color: ${Color.Transparent};
    padding: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: ${spacing.x2};
    grid-row-gap: ${spacing.x2};
    grid-template-columns: repeat(3, 225px);
  `};

  ${above.md`
    grid-template-columns: repeat(auto-fit, 250px);
  `}
`;
